import classNames from "classnames";
import React from "react";

import "./Section.scss";

export const Section = ({ children, className }) => {
  const sectionClass = classNames("Section", className);

  return (
    <div data-testid="section" className={sectionClass}>
      {children}
    </div>
  );
};

Section.defaultProps = {
  children: null,
  className: "",
};

export default Section;
