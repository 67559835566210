import React, { useState} from "react";
import { haveCommonItems } from "../utils/common";
import moment from "moment";
import { AddressField } from "../controls/AddressField/AddressField";
import { Button, DatePicker, Flex, Input, Select} from "antd";
import { Text } from "../controls/Text/Text";

import { LOCATIONS } from "../utils/form";

import "./Form.scss";

export const TRADES_VALUE = "Trades";

const NEEDS_INSTALL_PRODUCTS = [
  "Home Phone", "Internet", "Optik Evolution (DIY Install)", "Optik Evolution (Fielded install)", "WiFi Plus"
]

export const Form = ({ onSubmit }) => {
  const [payload, setPayload] = useState({ custom_fields: {} });

  const showDateOfInstall = payload.worklonger === "No" || haveCommonItems(NEEDS_INSTALL_PRODUCTS, payload.custom_fields.productsforinstall || [])

  function changeField(fieldId, event, isCustomField) {
    const newPayload = {...payload};

    if (isCustomField) {
      newPayload.custom_fields[fieldId] = event.target.value;
    } else {
      newPayload[fieldId] = event.target.value;
    }
    

    setPayload(newPayload);
  }

  function changeAddress(changePayload) {
    const newPayload = {...payload};

    newPayload[changePayload.id] = changePayload.value;

    setPayload(newPayload);
  }

  function changeSelect(id, value, isCustomField) {
    const newPayload = {...payload};

    if (isCustomField) {
      newPayload.custom_fields[id] = value;
    } else {
      newPayload[id] = value;
    }

    setPayload(newPayload);
  }

  function changeDate(id, value, isCustomField) {
    const newPayload = {...payload};

    const isoValue = moment(value).toISOString();

    if (isCustomField) {
      newPayload.custom_fields[id] = isoValue;
    } else {
      newPayload[id] = isoValue;
    }

    setPayload(newPayload);
  }

  function submitForm() {
    if (!showDateOfInstall) {
      delete payload.custom_fields.dateofinstallation
    }

    onSubmit(payload);
  }

  const isFormDisabled = !payload.custom_fields.phonenumber || !payload.location;

  return (
    <Flex vertical gap={16}>
      <div className="Form__field">
        <label htmlFor="name">Customer Name</label>
        <Input id="name" onChange={(value) => changeField("name", value, false)} />
      </div>
      <div className="Form__field">
        <label htmlFor="phonenumber">Customer Phone *</label>
        <Input id="phonenumber" placeholder="(123) 456-7890" onChange={(value) => changeField("phonenumber", value, true)} />
      </div>
      <div className="Form__field">
        <label htmlFor="location">Location *</label>
        <Select onChange={(value) => changeSelect("location", value, false)} options={LOCATIONS.map((location) => { return { label: location, value: location }})} />
      </div>
      <div className="Form__field">
        <label htmlFor="address">Customer Address</label>
        <AddressField id="address"  onChange={changeAddress} />
      </div>
      <div className="Form__field">
        <label htmlFor="productsforinstall">Products to be Installed</label>
        <Select
          onChange={(value) => changeSelect("productsforinstall", value, true)}
          size="large"
          mode="multiple"
          id="productsforinstall"
          options={[{
            value: "Home Phone",
            label: "Home Phone",
          }, {
            value: "Internet",
            label: "Internet",
          }, {
            value: "Optik Evolution (DIY Install)",
            label: "Optik Evolution (DIY Install)",
          }, {
            value: "Optik Evolution (Fielded install)",
            label: "Optik Evolution (Fielded install)",
          }, {
            value: "WiFi Plus",
            label: "WiFi Plus",
          }, {
            value: "Custom Home Security",
            label: "Custom Home Security"
          }, {
            value: "Custom Home Security with POE Cameras",
            label: "Custom Home Security with POE Cameras",
          }, {
            value: "CCTV",
            label: "CCTV",
          }, {
            value: TRADES_VALUE,
            label: TRADES_VALUE
          }]}
        />
      </div>
      <div className="Form__field">
        <label htmlFor="fifaordernum">Please provide the FIFA order number if applicable.</label>
        <Input id="fifaordernum" onChange={(value) => changeField("fifaordernum", value, true)} />
        <Text type="S">NOTE: When creating the FIFA orders, please ensure to use Special Project Code: "Tadpole". If a sooner appointment date is preferable, please choose an available date and select YES to the following question for manual booking.</Text>
      </div>
      <div className="Form__field">
        <label htmlFor="workordernumber">Work Order Number</label>
        <Input id="workordernumber" onChange={(value) => changeField("workordernumber", value, true)} />
      </div>
      <div className="Form__field">
        <label htmlFor="worklonger">Is the work required longer than 7 hours?</label>
        <Select
          onChange={(value) => changeSelect("worklonger", value, false)}
          size="large"
          id="worklonger"
          options={[{
            value: "Yes",
            label: "Yes",
          }, {
            value: "No",
            label: "No",
          }]}
        />
      </div>
      {
        showDateOfInstall && (
          <div className="Form__field">
            <label id="dateofinstallation">Date of Install</label>
            <DatePicker id="dateofinstallation" showTime onChange={(_, value) => changeDate("dateofinstallation", value, true)} />
          </div>
        )
      }
      <div className="Form__field">
        <Button onClick={submitForm} disabled={isFormDisabled}>Send</Button>
      </div>

    </Flex>
  )
}