import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import "./Text.scss";

export class Text extends React.Component {
  static defaultProps = {
    type: "P",
    weight: "Regular",
  };

  static propTypes = {
    /** Additional class names for the component */
    className: PropTypes.string,
    /** Id associated with component */
    id: PropTypes.any,
    /** Label associated with component */
    label: PropTypes.string,
    /** HTML tag to be rendered */
    type: PropTypes.oneOf(["H1", "H2", "H3", "H4", "H5", "H6", "P", "S", "T"]),
    /** Additional styling to add to html */
    style: PropTypes.object,
    /** Children to be rendered */
    children: PropTypes.node,
    /** The weight of the text */
    weight: PropTypes.oneOf(["Bold", "Light", "Medium", "Regular", "Semi"]),
  };

  renderHtmlType = () => {
    const { children, className, id, label, style, type, weight } = this.props;

    const textClass = classNames("Text", type, weight, className);

    const sharedProps = {
      className: textClass,
      id,
      label,
      style,
    };

    let html;
    switch (type) {
      case "H1":
        html = <h1 {...sharedProps}>{children}</h1>;
        break;
      case "H2":
        html = <h2 {...sharedProps}>{children}</h2>;
        break;
      case "H3":
        html = <h3 {...sharedProps}>{children}</h3>;
        break;
      case "H4":
        html = <h4 {...sharedProps}>{children}</h4>;
        break;
      case "H5":
        html = <h5 {...sharedProps}>{children}</h5>;
        break;
      case "H6":
        html = <h6 {...sharedProps}>{children}</h6>;
        break;
      case "T":
        html = <h1 {...sharedProps}>{children}</h1>;
        break;
      case "S":
        html = <small {...sharedProps}>{children}</small>;
        break;
      default:
        html = <p {...sharedProps}>{children}</p>;
    }
    return html;
  };

  render() {
    return this.renderHtmlType();
  }
}

export default Text;
