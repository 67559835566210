import React, { useState } from "react";
import { Section } from "./controls/Section/Section";
import { TRADES_VALUE, Form } from "./Form/Form";
import { TECHNICIAN_LEAD_FORMS, TRADE_LEAD_FORMS } from "./utils/form";
import { axiosPost } from "./utils/axiosRequests";
import { Success } from "./Success/Success";
import { Wrapper } from "@googlemaps/react-wrapper";
import { StyleProvider } from "./controls/StyleProvider/StyleProvider";

import './App.scss';

const LEAD_FORMS_ENDPOINT = (leadFormId) => `https://api.wingmateapp.com/app/v1.0/lead_forms/${leadFormId}/submissions.js`;

function App() {
  const [page, setPage] = useState("form");

  async function submitForm(formPayload) {

    let formsToQuery = TECHNICIAN_LEAD_FORMS;
    const products = formPayload.custom_fields.productsforinstall || [];
    if (products.includes(TRADES_VALUE)) {
      formsToQuery = TRADE_LEAD_FORMS;
    }

    const leadFormId = formsToQuery[formPayload.location];

    await axiosPost(
      LEAD_FORMS_ENDPOINT(leadFormId),
      {
        lead: formPayload,
      }
    )

    setPage("success");
  }

  function renderPage() {
    if (page === "form") {
      return (
        <Form onSubmit={submitForm} />
      )
    } else if (page === "success") {
      return (
        <Success />
      )
    }
  }

  function renderLoader(status) {
    return status === "LOADING" ? <></> : null;
  }

  return (
    <div className="App">
      <Wrapper
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        libraries={["places", "drawing"]}
        render={(status) => renderLoader(status)}
      >
        <StyleProvider>
          <Section>
            <img className="Telus" src="/TelusXWingmate.png" alt="Wingmate X Telus" />
            <hr />
            {renderPage()}
          </Section>
        </StyleProvider>
      </Wrapper>
    </div>
  );
}

export default App;
