export const LOCATIONS = ["Vaughan", "Signal hill", "Westmount"];

export const TRADE_LEAD_FORMS = {
  "Vaughan": 163,
  "Signal hill": 164,
  "Westmount": 167,
}

export const TECHNICIAN_LEAD_FORMS = {
  "Vaughan": 168,
  "Signal hill": 166,
  "Westmount": 169,
}
