import React from "react";
import { ConfigProvider } from "antd";

export const theme = {
  token: {
    borderRadius: '5px',
    fontFamily: 'Roboto',
  },
  components: {
    InputNumber: {
      controlWidth: '100%',
      handleVisible: true,
      handleFontSize: 10,
    },
  },
};

export const StyleProvider = ({ children }) => {
  return <ConfigProvider theme={theme}>{children}</ConfigProvider>;
};

export default StyleProvider;
