import { Text } from "../controls/Text/Text";
import React from "react";

import "./Success.scss";

export const Success = () => {

  return (
    <div className="Success">
      <img className="Checkmark" src="/checkmark.png" alt="Checkmark" />
      <Text type="H3">Thank you for your submission!</Text>
    </div>
  )
}