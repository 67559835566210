import React, { Component } from "react";
import PropTypes from "prop-types";
import { FlexRow, Text } from "wm-ui-toolkit";

import "./Address.scss";

export class Address extends Component {
  static propTypes = {
    /** An array of objects that specifies the portions of text to be bolded.
      'offset' tells the start index where the bold text begins.
      'length' tells how many characters to bold from the start index. */
    offsets: PropTypes.arrayOf(
      PropTypes.shape({
        offset: PropTypes.number.isRequired,
        length: PropTypes.number.isRequired,
      })
    ).isRequired,
    /** The original string that will be partly bolded based on 'offsets'. */
    originalString: PropTypes.string.isRequired,
  };

  replaceSpaces = (str) => {
    return str.split("").map((char, index) => {
      return char === " " ? (
        <span key={`spaceSpan-${index}`}>&nbsp;</span>
      ) : (
        char
      );
    });
  };

  renderNormalText = (text, keyIndex) => (
    <Text key={`normal-${keyIndex}`}>{this.replaceSpaces(text)}</Text>
  );

  renderBoldText = (text, keyIndex) => (
    <Text key={`bold-${keyIndex}`} weight="Semi">
      {this.replaceSpaces(text)}
    </Text>
  );

  getTextFromOffsets = (offsets, originalString) => {
    return offsets.map(({ length, offset }, index, arr) => {
      const prevOffset =
        index === 0 ? 0 : arr[index - 1].offset + arr[index - 1].length;
      return [
        originalString.slice(prevOffset, offset),
        originalString.slice(offset, offset + length),
      ];
    });
  };

  assembleComponents = (slicedTexts) => {
    let keyIndex = 0;
    return slicedTexts.reduce((acc, [normal, bold]) => {
      const newComponents = [
        this.renderNormalText(normal, keyIndex),
        this.renderBoldText(bold, keyIndex + 1),
      ];
      keyIndex += 2;
      return acc.concat(newComponents);
    }, []);
  };

  appendLastTextComponent = (components, offsets, originalString) => {
    components.push(
      this.renderNormalText(
        originalString.slice(
          offsets[offsets.length - 1].offset +
            offsets[offsets.length - 1].length
        ),
        components.length
      )
    );
  };

  render() {
    const { offsets, originalString } = this.props;

    const slicedTexts = this.getTextFromOffsets(offsets, originalString);
    const components = this.assembleComponents(slicedTexts);
    this.appendLastTextComponent(components, offsets, originalString);

    return <FlexRow className="Address">{components}</FlexRow>;
  }
}

export default Address;
