import axios from "axios";

export async function axiosGet(endpoint, params = {}, additionalHeaders = {}) {
  const response = await axios.get(endpoint, {
    params,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...additionalHeaders,
    },
  });

  return {
    headers: response.headers,
    data: response.data,
    status: response.status,
  };
}

export async function axiosPost(endpoint, payload, additionalHeaders = {}) {
  const response = await axios.post(endpoint, payload, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...additionalHeaders,
    },
  });

  return response;
}

export async function axiosPut(endpoint, payload, additionalHeaders = {}) {
  const response = await axios.put(endpoint, payload, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...additionalHeaders,
    },
  });

  return response;
}

export async function axiosDelete(endpoint, payload, additionalHeaders = {}) {
  const response = await axios.delete(endpoint, {
    data: payload,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...additionalHeaders,
    },
  });

  return response;
}
